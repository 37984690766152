import React from 'react';
import './RecordInfo.css'; // 导入样式文件

const RecordInfo = () => {
  return (
    <div>
      <a href="https://beian.miit.gov.cn" target="_blank">蜀ICP备2024053938号-1  </a>
      <a href="https://beian.mps.gov.cn/#/query/webSearch?code=33018502002378" target="_blank">
      <img  src="https://noooo.cc/img/imgBeian.png"  style={{ width: '12px', height: '12px' }}  />浙公网安备33018502002378 </a>
    </div>
  );
}

export default RecordInfo;

